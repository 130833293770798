<script setup>
    import InputError from '@/Components/InputError.vue'
    import PrimaryButton from '@/Components/PrimaryButton.vue'
    import TextInput from '@/Components/TextInput.vue'
    import Checkbox from '@/Components/Checkbox.vue'
    import { Link, useForm, usePage } from '@inertiajs/vue3'

    import { ref, onMounted, onUnmounted, watch, computed } from 'vue'
    import ApplicationLogo from '@/Components/ApplicationLogo.vue'
    import Toast from '@/Components/Toast.vue'

    const showRegisterForm = ref(false)
    const isFormValid = ref(false)
    const isLoginValid = ref(false)
    const showPassword = ref(false)
    const showLoginPassword = ref(false)

    const showBannedToast = ref(false)
    const bannedToastText = ref('Your account has been locked, please contact Customer Support.')

    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get('type') === 'join') {
        showRegisterForm.value = true
    }

    if (urlParams.get('banned') == 1) {
        showBannedToast.value = true
    }

    let optPrompt = false

    const form = useForm({
        email: '',
        profile_url: '',
        name: '',
        password: '',
        password_confirmation: '',
        email_monthly_newsletter: false,
        tos: false,
        creator_signup: false,
        conversion_user_id: urlParams.get('conversion_user_id'),
        float_user_id: urlParams.get('float_user_id'),
    })

    const login_form = useForm({
        email: '',
        password: '',
        remember: false,
        otp: '',
    })

    const backref = usePage().props.backref

    const comeFromMessage = usePage().props.backref.includes('message')
    const comeFromMessageNav = usePage().props.backref.includes('nav')

    defineProps({
        canResetPassword: {
            type: Boolean,
        },
        status: {
            type: String,
        },
    })

    const toggleForms = () => {
        showRegisterForm.value = !showRegisterForm.value
    }

    const login_submit = () => {
        login_form.post(route('login'), {
            onFinish: () => login_form.reset('password'),
            onError: error => {
                if (usePage().props.errors.otp) {
                    optPrompt = true
                }
                form.errors.tos = error.email
            },
        })
    }

    const submit = () => {
        if (!form.tos) {
            form.errors.tos = 'Please accept terms & conditions'
            return
        } else {
            form.errors.tos = ''
        }

        form.post(route('register'), {
            onFinish: () => {
                form.reset('password', 'password_confirmation')

                if (comeFromMessage && !comeFromMessageNav) {
                    fathom.trackEvent('exn_lander_signup_completed')
                } else if (comeFromMessageNav && !comeFromMessage) {
                    fathom.trackEvent('exn_lander_nav_signup_completed')
                }
            },
        })
    }
    onMounted(() => {
        document.body.classList.add('dark')
        document.body.classList.add('login-page')
    })
    onUnmounted(() => {
        document.body.classList.remove('dark')
        document.body.classList.remove('login-page')
    })

    watch(
        () => [form.profile_url, form.email, form.password],
        () => {
            isFormValid.value =
                form.profile_url.trim() !== '' &&
                form.email.trim() !== '' &&
                form.password.trim() !== ''
        },
    )

    watch(
        () => [login_form.email, login_form.password],
        () => {
            isLoginValid.value = login_form.email.trim() !== '' && login_form.password.trim() !== ''
        },
    )

    const fromNav = computed(() => {
        return comeFromMessageNav
    })

    onMounted(() => {
        fromNav
    })
    const closeButton = () => {
        showErrorToast.value = false
        showSuccessToast.value = false
    }
</script>

<template>
    <div
        id="access-login"
        class="flex min-h-screen flex-col items-center rounded-l dark:bg-black sm:justify-center sm:pt-0">
        <div id="access-box" class="flex max-h-[680px] flex-row">
            <div
                class="h-[678px] w-[375px] max-w-[375px] rounded-[10px] bg-white px-4 py-4 dark:bg-[#0a0a0a] sm:max-w-md sm:rounded-lg">
                <div class="my-5 flex shrink-0 items-center justify-center">
                    <Link :href="route('home')">
                        <ApplicationLogo id="logo" />
                    </Link>
                </div>

                <ul class="flex text-center text-sm font-medium text-gray-200">
                    <li class="w-full">
                        <a
                            href="#"
                            :class="{ active: showRegisterForm }"
                            class="flex w-full items-center justify-center border-b-2 border-[#444444] px-8 pb-4 pt-2 text-[21px]"
                            aria-current="page"
                            @click.prevent="showRegisterForm = true"
                            >Sign Up</a
                        >
                    </li>
                    <li class="w-full">
                        <a
                            href="#"
                            :class="{ active: !showRegisterForm }"
                            class="flex w-full items-center justify-center border-b-2 border-[#444444] px-8 pb-4 pt-2 text-[21px]"
                            @click.prevent="showRegisterForm = false"
                            >Log In</a
                        >
                    </li>
                </ul>

                <form class="mt-10 space-y-6" @submit.prevent="submit" v-if="showRegisterForm">
                    <div class="relative">
                        <label
                            class="absolute left-5 top-[-10px] bg-darkgold-fieldbackground text-[12px] text-[#ccc]">
                            Email
                        </label>
                        <TextInput
                            id="email"
                            type="email"
                            class="access-field mt-1 block w-full border-darkgold-fieldborder bg-darkgold-fieldbackground focus:border-[#65e8a4]"
                            v-model="form.email"
                            required
                            autocomplete="email"
                            placeholder="e.g. Janedow@extranaughty.com" />

                        <InputError class="mt-2" :message="form.errors.email" />
                    </div>

                    <div class="relative">
                        <label
                            class="absolute left-5 top-[-10px] bg-darkgold-fieldbackground text-[12px] text-[#ccc]">
                            Display Name
                        </label>
                        <TextInput
                            id="name"
                            type="text"
                            class="access-field mt-1 block w-full border-darkgold-fieldborder bg-darkgold-fieldbackground focus:border-[#65e8a4]"
                            v-model="form.name"
                            required
                            autocomplete="name"
                            placeholder="e.g. Jane Dow" />

                        <InputError class="mt-2" :message="form.errors.name" />
                    </div>

                    <div class="relative">
                        <label
                            class="absolute left-5 top-[-10px] bg-darkgold-fieldbackground text-[12px] text-[#ccc]">
                            Username
                        </label>
                        <TextInput
                            id="profile_url"
                            type="text"
                            class="access-field mt-1 block w-full border-darkgold-fieldborder bg-darkgold-fieldbackground focus:border-[#65e8a4]"
                            v-model="form.profile_url"
                            required
                            autocomplete="profile_url"
                            placeholder="e.g. Bluesky" />

                        <InputError class="mt-2" :message="form.errors.profile_url" />
                    </div>

                    <div class="relative">
                        <label
                            class="absolute left-5 top-[-10px] bg-darkgold-fieldbackground text-[12px] text-[#ccc]">
                            Password
                        </label>
                        <TextInput
                            id="password"
                            :type="showPassword ? 'text' : 'password'"
                            class="access-field mt-1 block w-full border-darkgold-fieldborder bg-darkgold-fieldbackground focus:border-[#65e8a4]"
                            v-model="form.password"
                            required
                            autocomplete="new-password"
                            placeholder="Password" />

                        <InputError class="mt-2" :message="form.errors.password" />
                    </div>

                    <div class="mt-4 block">
                        <label class="flex items-center">
                            <Checkbox
                                name="show_password"
                                v-model="showPassword"
                                class="border-darkgold-fieldborder bg-darkgold-fieldbackground focus:border-mintgreen" />
                            <span class="checkmark-text ml-2 text-sm">Show password</span>
                        </label>
                    </div>

                    <div class="mt-4 block">
                        <label class="flex items-center">
                            <Checkbox
                                name="email_monthly_newsletter"
                                v-model="form.email_monthly_newsletter"
                                class="border-darkgold-fieldborder bg-darkgold-fieldbackground focus:border-mintgreen" />
                            <span class="checkmark-text ml-2 text-sm"
                                >Yes, I want to receive the newsletter!</span
                            >
                        </label>
                    </div>

                    <div class="mt-4 block">
                        <label class="flex items-center">
                            <Checkbox
                                v-model="form.tos"
                                name="tos"
                                class="border-darkgold-fieldborder bg-darkgold-fieldbackground focus:border-mintgreen" />
                            <span class="checkmark-text ml-2 text-sm"
                                >I agree with the
                                <a :href="route('tos')" class="text-mintgreen" target="_blank"
                                    >terms & conditions</a
                                ></span
                            >
                        </label>
                        <InputError
                            v-if="login_form.errors.email && !showRegisterForm"
                            class="li mt-2"
                            :message="form.errors.tos" />
                    </div>

                    <div class="mt-4 flex flex-col items-center justify-end space-y-1 sm:space-y-5">
                        <PrimaryButton
                            id="signup-button"
                            class="no-opacity-change flex w-full items-center justify-center border-darkgold-fieldborder bg-darkgold-fieldbackground px-4 py-4 text-center text-gray-500 focus:border-mintgreen"
                            :class="{
                                'opacity-25': form.processing,
                                'is-valid': isFormValid,
                                'is-inactive': !isFormValid,
                            }"
                            :disabled="form.processing">
                            Sign Up
                        </PrimaryButton>

                        <Link
                            id="has-account"
                            :href="route('login')"
                            class="text-sm text-[#65e8a4] hover:text-[#41966a]">
                            I already have an account
                        </Link>
                    </div>
                </form>

                <form
                    class="mt-10 space-y-6"
                    @submit.prevent="login_submit"
                    v-if="!showRegisterForm">
                    <div class="relative">
                        <label
                            class="absolute left-5 top-[-10px] bg-darkgold-fieldbackground text-[12px] text-[#ccc]">
                            Email
                        </label>
                        <TextInput
                            id="email"
                            type="text"
                            class="access-field mt-1 block w-full border-darkgold-fieldborder bg-darkgold-fieldbackground focus:border-[#65e8a4]"
                            v-model="login_form.email"
                            required
                            autocomplete="username"
                            placeholder="e.g. Janedow@extranaughty.com" />

                        <InputError
                            v-if="login_form.errors.email && !showRegisterForm"
                            class="su mt-2"
                            :message="login_form.errors.email" />
                    </div>

                    <div class="relative mt-4">
                        <label
                            class="absolute left-5 top-[-10px] bg-darkgold-fieldbackground text-[12px] text-[#ccc]">
                            Password
                        </label>
                        <TextInput
                            id="password"
                            :type="showLoginPassword ? 'text' : 'password'"
                            class="access-field mt-1 block w-full border-darkgold-fieldborder bg-darkgold-fieldbackground focus:border-[#65e8a4]"
                            v-model="login_form.password"
                            required
                            autocomplete="current-password"
                            placeholder="Password" />

                        <InputError
                            v-if="login_form.errors.password"
                            class="mt-2"
                            :message="login_form.errors.password" />
                    </div>

                    <div v-if="optPrompt" class="mt-4">
                        <TextInput
                            id="otp"
                            type="text"
                            class="access-field mt-1 block w-full border-darkgold-fieldborder bg-darkgold-fieldbackground focus:border-[#65e8a4]"
                            v-model="login_form.otp"
                            required
                            autocomplete="otp"
                            placeholder="Verification Code" />
                        <InputError
                            v-if="login_form.errors.otp"
                            class="mt-2"
                            :message="login_form.errors.otp" />
                    </div>

                    <div class="flex justify-between">
                        <div class="mt-4 block">
                            <label class="flex items-center">
                                <Checkbox
                                    class="border-darkgold-fieldborder bg-darkgold-fieldbackground focus:border-mintgreen"
                                    name="remember"
                                    v-model:checked="login_form.remember" />
                                <span class="checkmark-text ml-2 text-sm">Remember me</span>
                            </label>
                        </div>
                        <div class="mt-4 block">
                            <label class="flex items-center">
                                <Checkbox
                                    class="border-darkgold-fieldborder bg-darkgold-fieldbackground focus:border-mintgreen"
                                    name="remember"
                                    v-model="showLoginPassword" />
                                <span class="checkmark-text ml-2 text-sm">Show Password</span>
                            </label>
                        </div>
                    </div>

                    <div class="mt-4 flex flex-col items-end justify-end space-y-1 sm:space-y-5">
                        <PrimaryButton
                            id="login-button"
                            class="flex w-full items-center justify-center border-darkgold-fieldborder bg-darkgold-fieldbackground px-4 py-4 text-center text-[#444444] focus:border-mintgreen"
                            :class="{
                                'is-valid': isLoginValid,
                                'is-inactive': !isLoginValid,
                            }"
                            :disabled="login_form.processing">
                            Log in
                        </PrimaryButton>
                        <Link
                            id="forgot-password"
                            v-if="canResetPassword"
                            :href="route('password.request')"
                            class="self-center text-sm text-[#65e8a4] underline hover:text-[#41966a] sm:self-end">
                            Forgot your password?
                        </Link>
                    </div>
                </form>
            </div>
        </div>
        <toast
            :show="showBannedToast"
            transitionName="slide-fade"
            :text="bannedToastText"
            :transitionEnter="300"
            :transitionLeave="500"
            position="bottomLeft"
            @closeButton="closeButton" />
    </div>
</template>

<style>
    .active {
        border-color: #65e8a4 !important;
        color: #ffffff !important;
    }

    #access-login {
        background-repeat: repeat;
        background-image: url('./../../../../public/images/signup-background.png');
    }

    #access-image {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }

    #signup-button,
    #login-button {
        border-radius: 25px;
        font-size: 16px;
        text-transform: capitalize;
    }

    form input {
        border-color: #444;
        color: #efefef;
    }

    .access-field:focus {
        border-color: #65e8a4;
        --tw-ring-opacity: 0;
        --tw-ring-color: none;
    }

    #access-box {
        border-style: solid;
        border-color: #363636;
        border-width: 1px;
        border-radius: 10px;
    }

    .is-valid {
        background-color: #0a0a0a;
        border-color: #65e8a4;
        color: #65e8a4;
    }

    .is-valid:hover {
        background-color: #65e8a4;
        color: #000;
    }

    .is-inactive,
    button.is-inactive:hover {
        border-color: #444;
        color: #444;
        background-color: #0a0a0a;
    }

    #logo {
        margin-top: 0px;
        margin-bottom: 20px;
        width: 215px;
    }

    #signing-up {
        margin: 0;
    }

    [type='checkbox']:checked,
    [type='radio']:checked,
    .dark [type='checkbox']:checked,
    .dark [type='radio']:checked {
        --tw-bg-opacity: 1;
        background-color: rgb(10 10 10 / var(--tw-bg-opacity));
        border-color: #444;
    }

    .checkmark-text {
        color: #6b7280;
    }

    #has-account {
        margin: 20px 0 15px 0;
    }

    .input-label-text {
        color: #ccc;
    }

    .no-opacity-change {
        opacity: 1;
    }

    /**autocomplete hack to color background for webkit browsers**/
    form input:-webkit-autofill,
    form input:-webkit-autofill:hover,
    form input:-webkit-autofill:focus {
        -webkit-text-fill-color: #fff;
        -webkit-box-shadow: 0 0 0px 40rem #0a0a0a inset;
    }

    [type='checkbox']:focus {
        box-shadow: 0 0 4px #2196f3;
    }

    #signup-button:focus,
    #login-button:focus {
        border-color: #65e8a4 !important;
    }

    .focus\:ring-indigo-500:focus {
        --tw-ring-opacity: 1;
        --tw-ring-color: #65e8a4;
    }

    .focus\:ring-offset-2:focus {
        --tw-ring-offset-width: 0;
    }

    #forgot-password {
        margin-top: 15px;
    }

    @media (max-width: 512px) {
        #logo {
            margin-top: 5px;
            margin-bottom: 10px;
        }

        #access-login {
            margin-top: 10px;
        }
    }

    @media (max-width: 375px) and (max-width: 413px) {
        #access-box {
            width: 95%;
        }
    }
</style>
